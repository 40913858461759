import Vue from 'vue';
import VueRouter from 'vue-router';
import jsonwebtoken from 'jsonwebtoken';
import axios from 'axios';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'MainTemplate',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "main-template" */ '../views/MainTemplate.vue'),
    children: [
      {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '../views/index/Home.vue')
      }
    ]
  },
  {
    path: '/jobs',
    name: 'Jobs',
    redirect: { name: 'JobsList' },
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "jobs" */ '../views/MainTemplate.vue'),
    children: [
      {
        path: '',
        name: 'JobsList',
        component: () => import(/* webpackChunkName: "jobs-list" */ '../views/jobs/List.vue')
      },
      {
        path: 'create',
        name: 'JobsCreate',
        component: () => import(/* webpackChunkName: "jobs-create" */ '../views/jobs/Create.vue')
      },
      {
        path: ':id',
        name: 'JobsDetails',
        component: () => import(/* webpackChunkName: "jobs-details" */ '../views/jobs/Details.vue')
      }
    ]
  },
  {
    path: '/approval',
    name: 'Approval',
    meta: {
      requiresAuth: true
    },
    redirect: { name: 'ApprovalList' },
    component: () => import(/* webpackChunkName: "jobs" */ '../views/MainTemplate.vue'),
    children: [
      {
        path: 'list',
        name: 'ApprovalList',
        component: () => import(/* webpackChunkName: "jobs-details" */ '../views/approval/List.vue')
      }
    ]
  },
  {
    path: '/users',
    name: 'Users',
    meta: {
      requiresAuth: true
    },
    redirect: { name: 'UsersHome' },
    component: () => import(/* webpackChunkName: "users" */ '../views/MainTemplate.vue'),
    children: [
      {
        path: '',
        name: 'UsersHome',
        component: () => import(/* webpackChunkName: "users-home" */ '../views/users/Home.vue')
      }, {
        path: 'create',
        name: 'UsersCreate',
        component: () => import(/* webpackChunkName: "users-create" */ '../views/users/Create.vue')
      }, {
        path: ':id',
        name: 'UsersDetails',
        component: () => import(/* webpackChunkName: "users-details" */ '../views/users/Details.vue')
      }, {
        path: ':id/edit',
        name: 'UsersEdit',
        component: () => import(/* webpackChunkName: "users-edit" */ '../views/users/Edit.vue')
      }
    ]
  },
  {
    path: '/history',
    name: 'History',
    meta: {
      requiresAuth: true
    },
    redirect: { name: 'HistoryHome' },
    component: () => import(/* webpackChunkName: "users" */ '../views/MainTemplate.vue'),
    children: [
      {
        path: '',
        name: 'HistoryHome',
        component: () => import(/* webpackChunkName: "setting" */ '../views/history/Home.vue')
      }
    ]
  },
  {
    path: '/print',
    name: 'Print',
    meta: {
      requiresAuth: true
    },
    redirect: { name: 'PrintHome' },
    component: () => import(/* webpackChunkName: "users" */ '../views/MainTemplate.vue'),
    children: [
      {
        path: '',
        name: 'PrintHome',
        component: () => import(/* webpackChunkName: "setting" */ '../views/print/Home.vue')
      }
    ]
  },
  {
    path: '/setting',
    name: 'Setting',
    meta: {
      requiresAuth: true
    },
    redirect: { name: 'SettingHome' },
    component: () => import(/* webpackChunkName: "setting" */ '../views/MainTemplate.vue'),
    children: [
      {
        path: '',
        name: 'SettingHome',
        component: () => import(/* webpackChunkName: "setting" */ '../views/setting/Setting.vue')
      }
    ]
  },
  {
    path: '/customers',
    name: 'Customers',
    meta: {
      requiresAuth: true
    },
    redirect: { name: 'CustomersHome' },
    component: () => import(/* webpackChunkName: "customers" */ '../views/MainTemplate.vue'),
    children: [
      {
        path: '',
        name: 'CustomersHome',
        component: () => import(/* webpackChunkName: "CustomersHome" */ '../views/customer/Home.vue')
      }
    ]
  },
  {
    path: '/contents',
    name: 'Contents',
    meta: {
      requiresAuth: true
    },
    redirect: { name: 'ContentsHome' },
    component: () => import(/* webpackChunkName: "contents" */ '../views/MainTemplate.vue'),
    children: [
      {
        path: '',
        name: 'ContentsHome',
        component: () => import(/* webpackChunkName: "ContentsHome" */ '../views/contents/Home.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/verify-user',
    name: 'VerifyUser',
    component: () => import(/* webpackChunkName: "verify-user" */ '../views/VerifyUser.vue')
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "reset-password" */ '../views/ResetPassword.vue')
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import(/* webpackChunkName: "forgot-password" */ '../views/ForgotPassword.vue')
  },
  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '../views/404.vue')
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const jwt = localStorage.getItem('jwt') ? localStorage.getItem('jwt') : null;

  if (jwt) { // handle expire!
    const decoded = jsonwebtoken.decode(jwt);
    const expireTime = decoded.exp;
    const currentTime = Math.floor((new Date()).getTime() / 1000);
    if (expireTime <= currentTime) {
      const name = to.name;
      localStorage.removeItem('jwt');
      const refreshToken = localStorage.getItem('refreshToken');
      const username = localStorage.getItem('username');
      if (refreshToken) { // refresh new token
        axios.post(process.env.VUE_APP_BASE_URL + '/new-token', { username: username, refreshToken: refreshToken })
          .then(response => {
            const jwt = response.data.token;
            localStorage.setItem('jwt', jwt);
            return next({ name: name });
          })
          .catch(error => {
            console.log(error);
            return next({ name: 'Login', params: { nextUrl: name } });
          });
      } else {
        return next({ name: 'Login', params: { nextUrl: name } });
      }


    }
  }

  // set global header
  axios.defaults.headers = {
    Authorization: 'Bearer ' + jwt
  };

  // prevent login page after sign in already.
  if ('Login' === to.name && jwt) {
    return next({ name: 'Home' });
  }

  // require authentication
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!jwt) {
      return next({
        path: '/login',
        params: { nextUrl: to.fullPath }
      });
    }
  }
  next();
});

export default router;
