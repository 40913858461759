import Vue from 'vue';
import Vuex from 'vuex';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import Loading from 'vue-loading-overlay';
import * as VueGoogleMaps from 'vue2-google-maps';
import 'vue-loading-overlay/dist/vue-loading.css';
import store from './store';

Vue.use(Loading);
Vue.use(Vuex);

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_KEY,
    libraries: 'places'
  }
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
