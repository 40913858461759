const state = () => ({
    currentUser: null
});

const getters = {
    currentUser: (state) => {
        return state.currentUser ? state.currentUser : null;
    }
};

const mutations = {
    updateCurrentUser (state, currentUser) {
        state.currentUser = currentUser;
    }
};

const actions = {
    updateCurrentUser ({ commit }, currentUser) {
        commit('user/updateCurrentUser', currentUser, { root: true });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};